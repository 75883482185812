<template>
    <div class="card-tail" v-loading="$waiting.is('loading')">
        <div class="flex justify-between items-center border-b pb-3 mb-2">
            <div>
                <el-radio-group v-model="type" class="mr-3">
                    <el-radio-button label="unmatched"> Ej klara bg ({{ statusCount.unmatched }}) </el-radio-button>
                    <el-radio-button label="unmatched2"> Ej klara ibank</el-radio-button>
                    <el-radio-button label="matched"> Att betala ({{ statusCount.matched }}) </el-radio-button>
                    <el-radio-button label="unidentified"> Oidentifierade ({{ statusCount.unidentifiedUnmatched }}) </el-radio-button>
                </el-radio-group>
            </div>
            <div>
                <el-button type="primary" @click="generatePainFile" :loading="$waiting.is('loading')" :disabled="selectedItems.length === 0">Skapa en PAIN fil</el-button>
            </div>
        </div>

        <StatusBar v-if="type === 'unmatched'" :payments-data="itemsData" />

        <PaymentsTable
            :data="itemsData"
            :selected-type="type"
            @paymentSelected="paymentSelected"
            @markAsUnidentified="markAsUnidentified"
            @clearAsUnidentified="clearAsUnidentified"
            @selectionChanged="selectionChange"
        />
    </div>
</template>
<script>
import queryString from "query-string";
import Api from "../avstamning.api.js";

export default {
    props: {
        refreshCounter: {
            type: Number,
            default: 0,
        },
    },

    components: {
        PaymentsTable: () => import("./AvstamningPaymentsTable.vue"),
        StatusBar: () => import("./AvstamningPaymentsStatus.vue"),
    },

    data() {
        return {
            itemsData: [],
            selectedItems: [],
            type: "unmatched",
            status: 0,
            statusCount: {
                matched: 0,
                unmatched: 0,
                unidentifiedMatched: 0,
                unidentifiedUnmatched: 0,
            },
        };
    },

    watch: {
        refreshCounter() {
            this.getAll();
            this.getStatusCount();
        },

        type() {
            const query = queryString.parse(window.location.search);
            query.status = this.type;
            this.$router.replace({ query }).catch(() => {});

            if (this.type === "unmatched") {
                this.status = 0;
                this.getAll();
                return;
            } else if (this.type === "unmatched2") {
                this.status = 3;
                this.getAll();
                return;
            } else if (this.type === "matched") {
                this.status = 1;
            } else if (this.type === "unidentified") {
                this.status = 2;
            }

            this.getAll();
        },
    },

    created() {
        this.getAll();
        this.getStatusCount();
    },

    methods: {
        async getStatusCount() {
            this.statusCount = await Api.getStatusCount();
        },

        async getAll() {
            this.$waiting.start("loading");

            const response = await Api.getAllBankFiles(this.status);
            this.itemsData = response.data;

            this.$waiting.end("loading");
        },

        paymentSelected(id) {
            this.$emit("paymentSelected", id);
        },

        async markAsUnidentified(id) {
            this.$emit("markAsUnidentified", id);
        },

        async clearAsUnidentified(id) {
            this.$emit("clearAsUnidentified", id);
        },

        async selectionChange(ids) {
            this.selectedItems = ids;
        },

        async generatePainFile() {
            // make a summary of the selected items
            var summary = this.selectedItems.reduce((total, item) => total + item.amount, 0);
            var numberOfItems = this.selectedItems.length;
            const confirm = await this.$dialog.title("Skapa pain-fil").confirm("Är du säker att du vill skapa " + numberOfItems + "st utbetalningar med en totalsumma på " + summary + " SEK?");
            if (!confirm) return;
            console.log("🚀 - file: AvstamningPayments.vue:130 - generatePainFile - ", "Skapa pain-fil");
            this.$waiting.start("loading");
            await Api.createPainFile(this.selectedItems);
            this.$notify.success({ title: "Utfört" });
            this.getAll();
            this.$waiting.end("loading");
        },
    },
};
</script>
